import type { Emitter } from 'mitt'
import mitt from 'mitt'

type Events = {
  chartEnlarged: Number
  onCheckProcessTableDataSource: any
  onCheckFinancialDataSource: any
  selectDataSource: any
  onStartSync: any
  resetList: any
  financialsDataInit: any
  onCheckAllTransactions: any
  onFetchIntervalSourceList: any
  resetDuplicateDataList: any
  onClickedRegenerate: any
  onPushOpenCollapse: string
  onDeleteTableRow: string[]
  onEditTableRowEvent: any
}

export const emitter: Emitter<Events> = mitt<Events>()
