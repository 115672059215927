import {
  DateTimeType,
  FieldValueType,
  FinancialValueType,
  FinancialConfigType,
  FinancialDataItemType,
  FinancialStatementDataType
} from '#/FinancialsTypes'
import _ from 'lodash-es'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import {
  reportEventList,
  rollForwardFieldList,
  balanceSheetFieldList,
  generalLedgerFieldList,
  incomeStatementFieldList,
  cashFlowStatementFieldList,
  restrictionsCryptoAssetsFieldList
} from '@/config/reports'
import {
  ContactType,
  SourceListType,
  AccountItemType,
  ReportIssueType,
  CurrencyItemType,
  FinancialDataListType,
  TransactionFilterType,
  AccountMappingItemType
} from '#/ReportsTypes'
import ReportsApi from '@/api/ReportsApi'
import ProjectApi from '@/api/ProjectApi'
import { EntityDetailType } from '#/EntityType'
import { useUserGlobalStore } from '../global/user'

dayjs.extend(isSameOrAfter)
dayjs.extend(quarterOfYear)
export const useReportStore = defineStore({
  id: 'reportStore ',
  state: () => {
    const reportState: {
      financialFieldConfig: FinancialConfigType[]
      financialData: FinancialDataListType
      loading: Boolean
      agGridApi: any
      agGridColumnApi: any
      entityDetail: EntityDetailType
      currencyList: CurrencyItemType[]
      sourceList: SourceListType
      transactionFilter: TransactionFilterType
      contactList: ContactType[]
      accountList: AccountItemType[]
      batchEditSelected: any[]
      sourceDoingCount: number
      sourceSyncList: any[]
      regenerateReportList: any[]
      checkTransactionsAll: boolean
      issueList: ReportIssueType[]
      batchReviewParams: any
      unSelectedList: number[]
      transactionTotal: number
      reviewedTotal: number
      contactUnnamedList: any[]
      sourceConfigRuleList: any[]
      entityMemberList: any[]
      guidanceUnDisplayList: string[]
      activityEventList: string[]
      accountMapping: AccountMappingItemType
    } = {
      financialFieldConfig: [],
      financialData: {
        updatedOn: '',
        list: [],
        errorList: []
      },
      agGridApi: null,
      agGridColumnApi: null,
      loading: true,
      checkTransactionsAll: false,
      entityDetail: {
        entityId: 0,
        projectId: 0,
        name: '',
        logo: '',
        status: '',
        unit: '',
        creationDate: '',
        updateDate: '',
        updatedOn: '',
        slug: '',
        reportTime: '',
        operationTime: '',
        generateReportCount: 0,
        costBasisMethodology: '',
        region: '',
        defaultCurrency: '',
        guideStep: 0,
        progressStatus: '',
        hasIntegration: false,
        needRegenerateReport: false
      },
      currencyList: [],
      sourceList: {
        wallet: [],
        exchange: [],
        csv: [],
        api: []
      },
      sourceDoingCount: 0,
      sourceSyncList: [],
      regenerateReportList: [],
      contactList: [],
      accountList: [],
      transactionFilter: {},
      batchEditSelected: [],
      issueList: [],
      batchReviewParams: {},
      unSelectedList: [],
      sourceConfigRuleList: [],
      transactionTotal: 1,
      reviewedTotal: 0,
      contactUnnamedList: [],
      entityMemberList: [],
      guidanceUnDisplayList: [],
      activityEventList: [],
      accountMapping: {
        system: [],
        integration: []
      }
    }
    return reportState
  },
  actions: {
    /**
     * 格式化配置children
     * @param {string} type
     * @returns
     */
    formatChildrenFieldList() {
      const list = _.cloneDeep(this.financialFieldConfig)
      const mergedFieldValues = _.chain(this.financialData?.list)
        .map('fieldValues')
        .flatten()
        .groupBy('fieldId')
        .map((groupedValues, fieldId) => ({
          value: _.uniqBy(groupedValues, 'value')[0].value,
          fieldId
        }))
        .value()
      list.forEach((item: any, index: number) => {
        if (item.whetherExpanded) {
          const children = _.filter(mergedFieldValues ?? this.financialData?.list[0]?.fieldValues, (field: any) => {
            const { fieldId } = field
            if (_.includes(fieldId, `${item.fieldId}-`)) {
              const remainingStr = fieldId.replace(`${item.fieldId}-`, '')
              // eslint-disable-next-line no-restricted-globals, radix
              return isNaN(parseInt(remainingStr)) && remainingStr !== 'agg'
            }
            return false
          })
          if (children.length > 0) {
            const result: any = children.map((val: any) => {
              const symbol = val.fieldId.replace(`${item.fieldId}-`, '').toLowerCase()
              const data = _.find(this.currencyList, (currencyItem: any) => {
                return currencyItem.symbol.toLowerCase() === symbol
              })
              return {
                fieldId: val.fieldId,
                parentId: item.fieldId,
                parentName: item.name,
                config: { ...item.config, level: 3, reverse: false },
                whetherExpanded: false,
                popover: item.popover,
                ...data
              }
            })
            this.financialFieldConfig.splice(index + 1, 0, ...result)
          }
        }
      })
    },
    /**
     * 获取table-field配置
     * @param {string} type
     * @returns
     */
    async fetchTableFieldList(type: string) {
      try {
        switch (type) {
          case 'balance-sheet':
            this.financialFieldConfig = _.cloneDeep(balanceSheetFieldList)
            break
          case 'income-statement':
            this.financialFieldConfig = _.cloneDeep(incomeStatementFieldList)
            break
          case 'cash-flow-statement':
            this.financialFieldConfig = _.cloneDeep(cashFlowStatementFieldList)
            break
          case 'restrictions':
            this.financialFieldConfig = _.cloneDeep(restrictionsCryptoAssetsFieldList)
            break
          default:
            this.financialFieldConfig = _.cloneDeep(rollForwardFieldList)
            break
        }
        await this.formatChildrenFieldList()
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 财务报表数据初始化
     * @param {number} entityId
     * @param {string} type 报表类型 (balance-sheet/income-statement/cash-flow-statement)
     * @returns
     */
    async financialsDataInit(entityId: number, type: string) {
      try {
        this.loading = true
        this.financialData = {
          updatedOn: '',
          list: [],
          errorList: []
        }
        this.financialFieldConfig = []
        let response: any = {}
        switch (type) {
          case 'balance-sheet':
            response = await ReportsApi.getBalanceSheet(entityId)
            break
          case 'income-statement':
            response = await ReportsApi.getIncomeStatement(entityId)
            break
          case 'restrictions':
            response = await ReportsApi.getRestrictionsOfCryptoAssets(entityId)
            break
          case 'roll-forward':
            response = await ReportsApi.getCryptoAssetsRollForward(entityId)
            break
          default:
            response = await ReportsApi.getCashFlowStatement(entityId)
            break
        }
        this.financialData = response.data
        await this.fetchTableFieldList(type)
        this.loading = false
        return true
      } catch (error) {
        this.loading = false
        return Promise.reject(error)
      }
    },
    /**
     * 财务报表数据
     * @param {number[]} DataTimeList 时间段id
     * @returns {FinancialStatementDataType[]}
     */
    getFinancialStatementData(DataTimeList: number[] = []): FinancialStatementDataType[] {
      // 是否筛选时间段
      const financialData = _.clone(this.financialData.list)
      const data = DataTimeList.length
        ? _.filter(financialData, (item: FinancialDataItemType) => {
            return _.indexOf(DataTimeList, item.id) !== -1
          })
        : financialData
      const financialStatementData: any = _.clone(this.financialFieldConfig)
      const valueMaps = new Map()
      // 增加判断
      const mergedFieldValues = _.chain(this.financialData?.list)
        .map('fieldValues')
        .flatten()
        .groupBy('fieldId')
        .map((groupedValues, fieldId) => ({
          value: _.uniqBy(groupedValues, 'value')[0].value,
          fieldId
        }))
        .value()
      let fieldValuesData: any = mergedFieldValues ?? data[0].fieldValues
      if (_.isEmpty(fieldValuesData)) {
        fieldValuesData = data[1]?.fieldValues
      }
      fieldValuesData?.forEach((fieldValue: FieldValueType) => {
        const values: FinancialValueType[] = []
        data.forEach((configItem: FinancialDataItemType) => {
          const value: FinancialValueType = {
            fieldId: '',
            value: 0
          }
          const currentFieldValue = _.find(configItem.fieldValues, { fieldId: fieldValue.fieldId })
          if (!_.isEmpty(currentFieldValue)) {
            value.fieldId = currentFieldValue.fieldId
            value.value = currentFieldValue.value
          } else {
            value.fieldId = fieldValue.fieldId
            value.value = 0
          }

          if (configItem?.date) {
            value.date = configItem.date
          } else {
            value.title = configItem.title
            value.fromDate = configItem.fromDate
            value.toDate = configItem.toDate
          }
          values.push(value)
        })
        valueMaps.set(fieldValue.fieldId, values)
      })
      financialStatementData.forEach((item: FinancialStatementDataType) => {
        const values = valueMaps.get(item.fieldId)
        if (values) {
          item.fieldValues = values
          if (item?.config?.level === 2) {
            item.expanded = false
          }
        } else if (item.config?.level === 2 && !values) {
          item.fieldValues = this.dateTimeList.map(() => {
            return {
              fieldId: item.fieldId,
              value: ''
            }
          })
          item.expanded = false
        } else if (!item.config?.level && !values) {
          item.fieldValues = this.dateTimeList.map(() => {
            return {
              fieldId: item.fieldId,
              value: ''
            }
          })
          item.expanded = false
        }
      })
      // 添加线的类型 lineType(1: 细线，2：粗线)
      for (let i = 0; i < financialStatementData.length; i += 1) {
        if (financialStatementData[i].config?.level !== 1) {
          if (financialStatementData[i].type === 'AGGREGATION' && !financialStatementData[i].config?.hideBottomLine) {
            financialStatementData[i].lineType = 2
          } else if (
            financialStatementData[i + 1]?.type &&
            financialStatementData[i + 1].type === 'AGGREGATION' &&
            !financialStatementData[i + 1].config?.hideTopLine
          ) {
            financialStatementData[i].lineType = 2
          } else {
            financialStatementData[i].lineType = 1
          }
        }
      }
      // 过滤出来所有3级的数据
      const level3List = _.filter(financialStatementData, (item: FinancialStatementDataType) => {
        return item?.config?.level === 3
      })
      _.remove(financialStatementData, (item: FinancialStatementDataType) => {
        return item?.config?.level === 3
      })
      const level3GroupList = _.groupBy(level3List, 'parentId')
      _.forEach(_.keys(level3GroupList), (key: string) => {
        const items = _.get(level3GroupList, key)
        const newThirdSortData = _.orderBy(items, (o: any) => Math.abs(o?.fieldValues[0]?.value), 'desc')
        const fieldIndex = _.findIndex(financialStatementData, ['fieldId', key])
        financialStatementData.splice(fieldIndex + 1, 0, ...newThirdSortData)
      })
      // 删除没有值的数据
      _.remove(financialStatementData, (item: FinancialStatementDataType) => {
        const isEvery = _.every(item.fieldValues, (fieldValue: FinancialValueType) => {
          return fieldValue.value === 0 || fieldValue.value === ''
        })

        if (
          ((item?.config?.level === 2 || !item?.config?.level) && item?.config?.filter) ||
          item?.config?.level === 3
        ) {
          return isEvery
        }
        if (item?.config?.level === 1 && item?.config?.filter) {
          const childrenData = _.filter(financialStatementData, (i: FinancialStatementDataType) => {
            return i.fieldId.indexOf(`${item.fieldId}-`) !== -1
          })
          const isEmptyChildren = _.every(childrenData, (i: FinancialStatementDataType) => {
            return _.every(i.fieldValues, (fieldValue: FinancialValueType) => {
              return fieldValue.value === 0 || fieldValue.value === ''
            })
          })
          return isEmptyChildren
        }
        return false
      })
      return financialStatementData
    },
    /**
     * 获取general-ledger列表
     * @param {number} entityId
     * @param {Object} params
     * @property {string} accountId accountId
     * @property {number} page 1
     * @property {number} limit 20
     * @returns
     */
    async fetchGeneralLedgerList(entityId: number, params: any) {
      try {
        const { data } = await ReportsApi.getGeneralLedgerList(entityId, params)
        return { list: data }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取ContactsUnnamed列表
     * @param {number} entityId
     * @param {Object} params
     * @property {number} page 1
     * @property {number} limit 50
     * @returns
     */
    async fetchContactsUnnamedList(entityId: number, params: any) {
      try {
        const { data } = await ReportsApi.getContactUnnamedList(entityId, params)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Transaction列表
     * @param {number} entityId
     * @param {Object} data
     * @param {number} page 1
     * @param {number} limit 20
     * @returns
     */
    async fetchTransactionDataList(entityId: number, data: any) {
      try {
        const response = await ReportsApi.getActivityList(entityId, data)
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取问题中心列表
     * @param {number} entityId
     * @returns
     */
    async fetchIssueDataList(entityId: number) {
      try {
        const { data } = await ReportsApi.getIssueList(entityId)
        this.issueList = data
        return data
      } catch (error) {
        this.issueList = []
        return Promise.reject(error)
      }
    },
    /**
     * 获取Account列表
     * @param {number} entityId
     * @returns
     */
    async fetchAccountDataList(entityId: number) {
      try {
        const { data } = await ReportsApi.getProjectAccountList(entityId)
        this.accountList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取contacts列表
     * @param {number} entityId
     * @returns
     */
    async fetchContactDataList(entityId: number) {
      try {
        const { data } = await ReportsApi.getContactList(entityId)
        this.contactList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Holdings列表
     * @param {number} entityId
     * @returns
     */
    async fetchHoldingsDataList(entityId: number) {
      try {
        const { data } = await ReportsApi.getSignificantHoldings(entityId)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 财务过程表配置数据初始化
     * @param {number} entityId
     * @param {string} type 报表类型 (general-ledger/accounts/contactsNamed/holdings)
     * @returns
     */
    async processTableDataInit(entityId: number, type: string) {
      try {
        let response: any = {}

        switch (type) {
          case 'accounts':
            response = await this.fetchAccountDataList(entityId)
            break
          case 'contactsNamed':
            this.batchEditSelected = []
            response = await this.fetchContactDataList(entityId)
            break
          case 'holdings':
            response = await this.fetchHoldingsDataList(entityId)
            break
          default:
            response = await {
              list: generalLedgerFieldList
            }
            break
        }
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 财务过程表数据初始化
     * @param {number} entityId
     * @param {string} type 报表类型 (general-ledger/transactions/contactsUnnamed)
     * @param {number | string} paramsId 个别接口参数需要id (accountId)
     * @returns
     */
    async processTableListDataInit(entityId: number, type: string, params?: any) {
      try {
        let response: any = {}
        switch (type) {
          case 'general-ledger':
            response = await this.fetchGeneralLedgerList(entityId, {
              accountId: String(params?.paramsId),
              page: params.page,
              limit: params.limit
            })
            break
          case 'contactsUnnamed':
            // eslint-disable-next-line no-case-declarations
            const { data } = await ReportsApi.getContactUnnamedList(entityId, {
              page: params.page,
              limit: params.limit
            })
            response = data
            break
          default:
            response = await this.fetchTransactionDataList(entityId, {
              reviewStatus: type === 'review' ? 'FOR_REVIEW' : 'REVIEWED',
              ...this.transactionFilter,
              ...params
            })
            if (params.limit !== 10) {
              this.transactionTotal = response.total
            }
            if (type === 'reviewed' || type === 'categorized') this.reviewedTotal = response.totalCount
            break
        }
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * @description: 设置Ag-Grid的Api
     * @param {any} api
     * @param {any} columnApi
     */
    setAgGridApi(api: any, columnApi: any) {
      this.agGridApi = api
      this.agGridColumnApi = columnApi
    },
    /**
     * 获取交易同步状态
     * @param {number} entityId 项目id
     */
    async fetchSourceSyncStatus(entityId: number) {
      try {
        const response = await ReportsApi.getSourceSyncStatus(entityId)
        this.sourceDoingCount = response.data.doingCount
        const isInSourceSyncList = this.sourceSyncList.find((e: any) => {
          return e.entityId === entityId
        })
        if (response.data.doingCount && _.isEmpty(isInSourceSyncList)) {
          this.sourceSyncList.push({ entityId, status: true, list: response.data.list })
        } else if (
          response.data.doingCount &&
          !_.isEmpty(isInSourceSyncList) &&
          isInSourceSyncList.list.length < response.data.doingCount &&
          this.sourceList.wallet.length +
            this.sourceList.exchange.length +
            this.sourceList.csv.length +
            this.sourceList.api.length
        ) {
          this.sourceSyncList.splice(
            this.sourceSyncList.findIndex((item: any) => item.entityId === entityId),
            1,
            { entityId, status: true, list: response.data.list }
          )
        }
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 编辑交易同步状态
     * @param {number} entityId 项目id
     * @param {boolean} status  交易同步状态
     * @param {Object} data 交易同步状态数据
     */
    editSourceSync(entityId: number, status: boolean, data: any, syncTotal: number = 0) {
      const isInSourceSyncList = this.sourceSyncList.find((e: any) => {
        return e.entityId === entityId
      })
      if (isInSourceSyncList) {
        if (this.sourceDoingCount === 0 && status) {
          this.sourceSyncList.splice(
            this.sourceSyncList.findIndex((item: any) => item.entityId === entityId),
            1,
            { entityId, status, syncTotal: syncTotal ?? null, list: syncTotal ? [...isInSourceSyncList.list] : [data] }
          )
        } else {
          this.sourceSyncList.splice(
            this.sourceSyncList.findIndex((item: any) => item.entityId === entityId),
            1,
            {
              entityId,
              status,
              syncTotal: syncTotal ?? null,
              list: status ? [...isInSourceSyncList.list, data] : [...isInSourceSyncList.list]
            }
          )
        }
      } else {
        this.sourceSyncList.push({ entityId, status, list: [data] })
      }
    },
    /**
     * 获取交易来源列表
     * @param {number} entityId 项目id
     * @param {string} type 交易来源类型 WALLET/EXCHANGE/CSV/API
     */
    async fetchSourceList(entityId: number, type?: string) {
      try {
        const response = await ReportsApi.getSourceList(entityId, type ? { type } : {})
        if (!_.isEmpty(response.data)) {
          const sourceTypeList: any = []
          _.forEach(response.data, (item: any) => {
            switch (item.type) {
              case 'WALLET':
                this.sourceList.wallet = item.list
                break
              case 'EXCHANGE':
                this.sourceList.exchange = item.list
                break
              case 'CSV':
                this.sourceList.csv = item.list
                break
              case 'API':
                this.sourceList.api = item.list
                break
              default:
                break
            }
            sourceTypeList.push(item.type)
          })
          // sourceTypeList中值没在this.sourceList的type中的，赋值为空数组
          _.forEach(this.sourceList, (item: any, key: string) => {
            if (!sourceTypeList.includes(key.toUpperCase())) {
              switch (key.toUpperCase()) {
                case 'WALLET':
                  this.sourceList.wallet = []
                  break
                case 'EXCHANGE':
                  this.sourceList.exchange = []
                  break
                case 'CSV':
                  this.sourceList.csv = []
                  break
                case 'API':
                  this.sourceList.api = []
                  break
                default:
                  break
              }
            }
          })
        } else {
          this.sourceList = {
            wallet: [],
            exchange: [],
            csv: [],
            api: []
          }
        }
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 项目详情初始化
     * @param {number} entityId 项目id
     */
    async concatDataInit(entityId: number) {
      const contactNamedList = new Promise((resolve, reject) => {
        ReportsApi.getContactList(entityId)
          .then((response) => {
            this.contactList = response.data
            return resolve(response.data)
          })
          .catch((e) => {
            return reject(e)
          })
      })
      const contactUnnamedList = new Promise((resolve, reject) => {
        ReportsApi.getContactUnnamedList(entityId, { page: 1, limit: 20 })
          .then((response) => {
            return resolve(response.data)
          })
          .catch((e) => {
            return reject(e)
          })
      })
      try {
        const response = await Promise.all([contactNamedList, contactUnnamedList])
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     *获取entity详情
     * @param {number} entityId 项目id
     */
    async fetchEntityDetail(entityId: number) {
      try {
        const response = await ReportsApi.getEntityDetail(entityId)
        this.entityDetail = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取entity成员列表
     * @param {number} entityId 项目id
     */
    async fetchEntityMemberList(entityId: number) {
      try {
        const response = await ProjectApi.getEntityMemberList(entityId)
        this.entityMemberList = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Currency列表
     * @param {number} entityId 项目id
     */
    async fetchCurrencyList(entityId: number) {
      try {
        const response = await ReportsApi.getCurrencyList(entityId)
        this.currencyList = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 项目详情初始化
     * @param {number} entityId 项目id
     */
    async projectInit(entityId: number) {
      this.loading = true
      const entityDetail = new Promise((resolve, reject) => {
        ReportsApi.getEntityDetail(entityId)
          .then((response) => {
            this.entityDetail = response.data
            return resolve(response.data)
          })
          .catch((e) => {
            return reject(e)
          })
      })
      const currencyList = new Promise((resolve, reject) => {
        ReportsApi.getCurrencyList(entityId)
          .then((response) => {
            this.currencyList = response.data
            return resolve(response.data)
          })
          .catch((e) => {
            return reject(e)
          })
      })

      const entityMemberList = new Promise((resolve, reject) => {
        ProjectApi.getEntityMemberList(entityId)
          .then((response) => {
            this.entityMemberList = response.data
            return resolve(response.data)
          })
          .catch((e) => {
            this.entityMemberList = []
            return reject(e)
          })
      })

      try {
        const response = await Promise.all([entityDetail, currencyList, entityMemberList])
        this.loading = false
        return response
      } catch (error) {
        this.loading = false
        return Promise.reject(error)
      }
    },
    /**
     * 获取Activity的Event
     * @param {number} entityId 项目id
     * @param {string} status REVIEWED | FOR_REVIEW
     */
    async fetchActivityEventList(entityId: number, status: string) {
      try {
        this.activityEventList = []
        const response = await ReportsApi.getActivityEvent(entityId, { status })
        this.activityEventList = response.data
        this.activityEventList = this.activityEventList.filter((item: string) => {
          const GainLoss = ['99994', '99995', '99996', '99997', '99998', '99999']
          if (_.includes(GainLoss, item)) return false
          return true
        })
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * transactions筛选数据初始化
     * @param {number} entityId 项目id
     */
    async transactionsFilterDataInit(entityId: number) {
      this.contactList = []
      this.sourceList = {
        wallet: [],
        exchange: [],
        csv: [],
        api: []
      }
      this.batchEditSelected = []
      this.unSelectedList = []
      this.contactUnnamedList = []
      let contactUnnamedList: any
      let contactList: any
      if (entityId !== 78) {
        contactUnnamedList = new Promise((resolve, reject) => {
          ReportsApi.getContactUnnamedList(entityId, { page: 1, limit: 15 })
            .then((response) => {
              this.contactUnnamedList = response.data?.list
              return resolve(response.data)
            })
            .catch((e) => {
              return reject(e)
            })
        })
        contactList = new Promise((resolve, reject) => {
          ReportsApi.getContactList(entityId)
            .then((response) => {
              this.contactList = response.data
              return resolve(response.data)
            })
            .catch((e) => {
              return reject(e)
            })
        })
      }
      const sourceList = new Promise((resolve, reject) => {
        ReportsApi.getSourceList(entityId)
          .then((response) => {
            if (!_.isEmpty(response.data)) {
              _.forEach(response.data, (item: any) => {
                switch (item.type) {
                  case 'WALLET':
                    this.sourceList.wallet = item.list
                    break
                  case 'EXCHANGE':
                    this.sourceList.exchange = item.list
                    break
                  case 'CSV':
                    this.sourceList.csv = item.list
                    break
                  default:
                    this.sourceList.api = item.list
                    break
                }
              })
            }
            return resolve(response.data)
          })
          .catch((e) => {
            return reject(e)
          })
      })
      try {
        const requestList = [sourceList]
        if (entityId !== 78) {
          requestList.push(...[contactList, contactUnnamedList])
        }
        const response = await Promise.all(requestList)
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 修改报表生成状态
     * @param {number} entityId 项目id
     * @param {boolean} status 状态
     * @param {boolean} deleted 是否删除报表生成状态
     * @param {string} type 报表类型
     */
    editRegenerateReport(entityId: number, status: boolean, deleted: boolean = false, type: string = '') {
      const isInRegenerateReportList = this.regenerateReportList.find((e: any) => {
        return e.entityId === entityId
      })
      if (isInRegenerateReportList) {
        const regenerateIndex = this.regenerateReportList.findIndex((item: any) => item.entityId === entityId)
        let data = { entityId, status }
        if (deleted) {
          data = { ...isInRegenerateReportList, entityId, status: true, [type]: false }
        }
        this.regenerateReportList.splice(regenerateIndex, 1, data)
      } else if (!deleted) {
        this.regenerateReportList.push({ entityId, status })
      }
    },
    /**
     * 获取配置规则列表
     * @param {number} entityId 项目id
     */
    async fetchSourceConfigRuleList(entityId: number) {
      try {
        const response = await ReportsApi.getSourceConfigRuleList(entityId)
        this.sourceConfigRuleList = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },

    /**
     * source规则初始化
     * @param {number} entityId 项目id
     */
    async sourceConfigRuleDataInit(entityId: number) {
      this.contactList = []
      this.sourceList = {
        wallet: [],
        exchange: [],
        csv: [],
        api: []
      }
      const sourceConfigRuleList = new Promise((resolve, reject) => {
        ReportsApi.getSourceConfigRuleList(entityId)
          .then((response) => {
            this.sourceConfigRuleList = response.data
            return resolve(response.data)
          })
          .catch((e) => {
            return reject(e)
          })
      })
      let contactList: any
      if (entityId !== 78) {
        contactList = new Promise((resolve, reject) => {
          ReportsApi.getContactList(entityId)
            .then((response) => {
              this.contactList = response.data
              return resolve(response.data)
            })
            .catch((e) => {
              return reject(e)
            })
        })
      }
      const accountList = new Promise((resolve, reject) => {
        ReportsApi.getProjectAccountList(entityId)
          .then((response) => {
            this.accountList = response.data
            return resolve(response.data)
          })
          .catch((e) => {
            return reject(e)
          })
      })
      const sourceList = new Promise((resolve, reject) => {
        ReportsApi.getSourceList(entityId)
          .then((response) => {
            if (!_.isEmpty(response.data)) {
              _.forEach(response.data, (item: any) => {
                switch (item.type) {
                  case 'WALLET':
                    this.sourceList.wallet = item.list
                    break
                  case 'EXCHANGE':
                    this.sourceList.exchange = item.list
                    break
                  case 'CSV':
                    this.sourceList.csv = item.list
                    break
                  default:
                    this.sourceList.api = item.list
                    break
                }
              })
            }
            return resolve(response.data)
          })
          .catch((e) => {
            return reject(e)
          })
      })

      try {
        const requestList = [sourceList, accountList, sourceConfigRuleList]
        if (entityId !== 78) {
          requestList.push(contactList)
        }
        const response = await Promise.all(requestList)
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取科目映射列表
     * @param {number} entityId 主体id
     */
    async fetchAccountMappingList(entityId: number) {
      try {
        const response = await ReportsApi.getAccountMappingList(entityId)
        this.accountMapping = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  getters: {
    // 返回所有时间列表
    dateTimeList() {
      const dateTimeList: DateTimeType[] = this.financialData.list.map((item: FinancialDataItemType, index: number) => {
        let dateTime: DateTimeType = {
          id: 0
        }
        if (item?.date) {
          dateTime = {
            id: item.id,
            date: item.date,
            title: item.date
          }
        } else {
          const today = dayjs() // 获取当前时间
          let quarterString
          const daysDifference = today.diff(item.fromDate, 'day')
          if (
            (dayjs(item.toDate).isSameOrAfter(today, 'day') ||
              daysDifference <= 90 ||
              today.diff(item.toDate, 'day') <= 90) &&
            index === 0
          ) {
            quarterString = 'Last 90 days'
          } else {
            const quarter = dayjs(item.fromDate).quarter()
            const year = dayjs(item.fromDate).year()
            quarterString = `Q${quarter}/${year}`
          }

          dateTime = {
            id: item.id,
            title: quarterString,
            fromDate: item.fromDate,
            toDate: item.toDate
          }
        }
        return dateTime
      })
      return dateTimeList
    },
    /**
     * 获取event数据列表
     * @param {string} dataSize all || in || out || internal || select-all || filter
     * @return {Function}
     */
    eventDataList:
      (state: any) =>
      (dataSize: string = 'all') => {
        let eventList: any = []
        if (dataSize === 'filter') {
          if (state.activityEventList.length) {
            eventList = _.filter(reportEventList, (item: any) => {
              return (
                state.activityEventList.includes(item.categoryNo) ||
                item.categoryNo === '50000' ||
                item.categoryNo === '00000'
              )
            })
          }
        } else {
          eventList = reportEventList
        }
        return eventList.reduce((acc: any, item: any) => {
          const { primaryGrouping, subGrouping } = item
          let group = acc.find((firstGroup: any) => firstGroup.groupName === primaryGrouping)

          if (!group) {
            group = {
              groupName: primaryGrouping,
              children: []
            }
            acc.push(group)
          }

          let childGroup = group.children.find((secondGroup: any) => secondGroup.secondGroupName === subGrouping)

          if (!childGroup) {
            childGroup = {
              secondGroupName: subGrouping,
              list: []
            }
            group.children.push(childGroup)
          }
          if (
            dataSize === 'all' ||
            dataSize === 'filter' ||
            (dataSize === 'select-all' &&
              item.primaryGrouping !== 'No set' &&
              item.primaryGrouping !== 'Gain/Loss adjusting') ||
            ((dataSize === item.tokenDirection ||
              item.categoryName === 'Ignore' ||
              item.tokenDirection === 'internal transfer' ||
              item.tokenDirection === '/') &&
              item.primaryGrouping !== 'Mark to market' &&
              item.primaryGrouping !== 'No set' &&
              item.primaryGrouping !== 'Gain/Loss adjusting')
          ) {
            childGroup.list.push({ id: item.categoryNo, name: item.categoryName, ...item })
          }

          return acc
        }, [])
      },
    allAccountList: () => {
      const allData = _.concat(
        rollForwardFieldList,
        balanceSheetFieldList,
        incomeStatementFieldList,
        cashFlowStatementFieldList,
        restrictionsCryptoAssetsFieldList
      )
      return _.uniqBy(allData, 'fieldId').map((item: any) => {
        return {
          fieldId: item.fieldId,
          name: item.name
        }
      })
    },
    entityPermission: (state) => {
      return (user?: any) => {
        const userGlobalStore = useUserGlobalStore()
        let permission: any = {}
        if (!_.isEmpty(user)) {
          permission = user.permission
        } else {
          permission = _.find(state.entityMemberList, {
            userId: userGlobalStore.user?.userId,
            email: userGlobalStore.user?.email
          })?.permission
        }
        const groups = _.groupBy(_.keys(permission), (key: any) =>
          key.replace(/^(.*?)Erp|ViewDetail|ViewList|View|Update|Generate|Export|Review|Create|Delete$/, '$1')
        )
        const result: any = {}
        _.forOwn(groups, (values: any, key: any) => {
          const newKey = _.camelCase(key)
          const newObj: any = {}
          _.forEach(values, (value: any) => {
            newObj[_.camelCase(value.replace(key, ''))] = permission[value]
          })
          result[newKey] = newObj
        })
        return result
      }
    }
  },
  // 本地化持久储存
  persist: {
    enabled: true,
    strategies: [
      {
        // 更改默认存储，我更改为localStorage
        storage: localStorage,
        // 默认是全部进去存储 可以选择哪些进入local存储，这样就不用全部都进去存储了
        paths: ['sourceSyncList', 'regenerateReportList', 'guidanceUnDisplayList']
      }
    ]
  }
})
